<template>
  <portal to="modal-aktivasi">
    <div class="modal-content modal-dialog-centered">
      <div class="overlay" id="overlay">
        <div class="modal-customAktivasi">
          <component :is="component" v-bind="$attrs" />
        </div>
      </div>
    </div>
  </portal>
</template>

<script>

export default {
  name: "ModalAktivasi",
  props: ["component"],
  data() {
    return {
      nilai: 'none',
    }
  },
  mounted(){
    this.$nextTick(() => {
      if (this.nilai == 'none' && document.getElementById('daftar_menu_id').style.display == 'block' || this.lebar === 640 || this.lebar < 640) document.getElementById('overlay').style.top = "173.76px";
      document.getElementById('tombolMenu').addEventListener("click", function () {
        this.nilai = document.getElementById('daftar_menu_id').style.display;
        if (this.nilai == 'none') document.getElementById('overlay').style.top = "135px";
        if (this.nilai == 'block') document.getElementById('overlay').style.top = "173.76px";
      }
      );
    });
  },
  methods: {
  }
};
</script>

<style scoped>






.modal-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.modal-customAktivasi {
  margin-left: 23.79%;
    margin-right: 23.79%;
    margin-top: 5%;
  /* position: absolute; */
  /* z-index: 1; */
  /* margin: auto; */
  /* left: 50%; */
  /* top: 44.2%; */
  width: 52.42%;
  /* transform: translate(-50%, -50%); */
  background-color: white;
  padding-top:3em;
  padding-left:5em;
  padding-right:5em;
  padding-bottom:3em;
  /* padding: 5em; */
  border: 1px solid #ccc;
  border-radius: 1em;
  box-shadow: 0 0 1em #00000033;
}

.overlay {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 135px;
  width: 100vw;
  height: 1053px;
  background: rgba(5, 58, 105, 0.38);
  /* backdrop-filter: blur(8px); */
}
@media screen and (min-device-width: 240px) and (min-width: 240px) and (max-device-width: 640px) and (max-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
  .overlay {
    width: 100%;
    z-index: 1;
    height: 1196px;
  }
  .modal-customAktivasi {
    width: 90%;
    height: auto;
    top: 41.96% !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
  
}
</style>
