import { render, staticRenderFns } from "./ModalAktivasi.vue?vue&type=template&id=639e215e&scoped=true"
import script from "./ModalAktivasi.vue?vue&type=script&lang=js"
export * from "./ModalAktivasi.vue?vue&type=script&lang=js"
import style0 from "./ModalAktivasi.vue?vue&type=style&index=0&id=639e215e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639e215e",
  null
  
)

export default component.exports